export const wrapVariants = {
  show: {
    opacity: 1,
    display: 'flex',
    transition: { duration: 0.3 },
  },
  hide: {
    opacity: 0,
    display: 'none',
    transition: {
      display: { delay: 0.1 },
      default: { duration: 0.3 },
    },
  },
};
