import { object, string } from 'yup';

import { NAME, ADDRESS, ZIP_CODE, CITY, PHONE, EMAIL, MESSAGE, INFO_REQUEST } from './consts';

export default object().shape({
  [NAME]: string().required('Bitte geben Sie Ihren Namen an'),
  [ADDRESS]: string().when(`$${INFO_REQUEST}`, {
    is: true,
    then: string().required('Bitte geben Sie Ihre Adresse an'),
    otherwise: string().notRequired(),
  }),
  [ZIP_CODE]: string().when(`$${INFO_REQUEST}`, {
    is: true,
    then: string().required('Bitte geben Sie Ihre Postleitzahl an'),
    otherwise: string().notRequired(),
  }),
  [CITY]: string().when(`$${INFO_REQUEST}`, {
    is: true,
    then: string().required('Bitte geben Sie Ihre Ortschaft an'),
    otherwise: string().notRequired(),
  }),
  [PHONE]: string().notRequired(),
  [EMAIL]: string().email('Bitte geben Sie eine gültige E-Mail-Adresse an').required('Bitte geben Sie Ihre E-Mail-Adresse an'),
  [MESSAGE]: string().required('Bitte geben Sie uns eine kurze Beschreibung Ihres Anliegens'),
});
