import React from 'react';
import cn from 'classnames';

const Links = ({ links, classes }) =>
  links.map(({ name, url, target }) => (
    <a key={url} href={url} target={target || '_self'} className={cn(classes.link, classes.parentLink)} rel="noopener noreferrer">
      {name}
    </a>
  ));

export default Links;
