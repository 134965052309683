export const postContactRequest = (data) =>
  new Promise((res, rej) => {
    fetch(`${process.env.GATSBY_API_URL}/contactrequests`, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': process.env.GATSBY_APP_TOKEN,
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then(({ error }) => rej(new Error(error)));
        }

        return res(response.json());
      })
      .catch(() => rej(new Error('Ihre Anfrage ist fehlgeschlagen. Bitte versuchen Sie es erneut.')));
  });

export const getContactInfo = (id) =>
  new Promise((res, rej) => {
    fetch(`${process.env.GATSBY_API_URL}/subscribers?token=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': process.env.GATSBY_APP_TOKEN,
      },
      method: 'GET',
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then(({ error }) => rej(new Error(error)));
        }

        return res(response.json());
      })
      .catch(() => rej(new Error('Ihre Anfrage ist fehlgeschlagen. Bitte versuchen Sie es erneut.')));
  });
