/* eslint-disable react/no-array-index-key */
import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';

import Links from '@/components/Links';

import styles from './styles';

const Content = ({ content, classes }) => {
  const { secondaryContent, mainContent } = content;
  return (
    <div className={classes.container}>
      <div className={classes.additionalContent}>
        <div className={classes.secondaryTitle}>{secondaryContent.title}</div>
        <div className={classes.text}>{secondaryContent.text}</div>
        <div className={classes.text}>
          <Links links={secondaryContent.links} />
        </div>
      </div>
      <div>
        <div className={classes.title}>{mainContent.title}</div>
        <div className={classes.subtitle}>
          {mainContent.subtitle.text}
          <br />
          {mainContent.subtitle.link.before} <Links links={mainContent.subtitle.link.links} /> {mainContent.subtitle.link.after}
        </div>
        <div className={classes.text}>{mainContent.text}</div>
      </div>
    </div>
  );
};

Content.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  content: PropTypes.shape({
    secondaryContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    mainContent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      subtitle: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.shape({
          before: PropTypes.string.isRequired,
          links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
          after: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default withStyles(styles)(Content);
