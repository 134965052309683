export default ({ spacing, fonts }) => ({
  container: {
    display: 'flex',
    margin: [spacing * 2, 0],
    '&:first-child': {
      marginTop: spacing * 3,
    },
    '&:nth-child(3)': {
      marginBottom: spacing * 5,
    },
  },
  wrapper: {
    position: 'relative',
    height: 18,
    width: 18,
    minWidth: 18,
    minHeight: 18,
    border: '1px solid #000',
    boxSizing: 'border-box',
    '& *': {
      cursor: 'pointer',
    },
    '@media (max-width: 799px)': {
      minWidth: 28,
      minHeight: 28,
      height: 28,
      width: 28,
    },
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '@media (max-width: 799px)': {
      '& svg': {
        width: 17,
        height: 17,
      },
    },
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    outline: 'none',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
  },
  text: {
    ...fonts.subtitle,
    padding: 0,
    marginLeft: 11,
  },
});
