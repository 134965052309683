export default ({ spacing, fonts }) => ({
  container: {
    ...fonts.subtitle,
    '@media (max-width: 799px)': {
      ...fonts.subtitle['@media (max-width: 799px)'],
      padding: [0, spacing * 1.5],
    },
  },
  additionalContent: {
    margin: [spacing * 4, 0],
  },
  secondaryTitle: {
    ...fonts.subtitleBold,
    marginBottom: 20,
  },
  title: {
    ...fonts.titleBold,
    marginBottom: 6,
    '@media (max-width: 799px)': {
      ...fonts.subtitleBold,
      margin: ['1em', 0],
    },
  },
  subtitle: {
    marginBottom: 20,
  },
});
