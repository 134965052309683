export default ({ colors, spacing, fonts, components }) => ({
  contactList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: spacing * 5,
    width: '100%',
    '@media (max-width: 799px)': {
      alignItems: 'flex-start',
    },
  },
  container: {
    margin: [0, spacing * 2],
    paddingBottom: components.curtain.height * 1.5,
    '@media (max-width: 799px)': {
      margin: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: [0, spacing * 2, 0],
    '&>div': {
      width: '50%',
    },
    '@media (max-width: 799px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: 0,
      '&>div': {
        width: '100%',
      },
    },
  },
  title: {
    marginTop: 2 * spacing,
    ...fonts.titleBold,
    '@media (max-width: 799px)': {
      ...fonts.titleBold['@media (max-width: 799px)'],
      padding: [0, spacing * 1.5],
    },
  },
  description: {
    ...fonts.title,
    color: colors.text.desktop,
    margin: [spacing, 0],
    '@media (max-width: 799px)': {
      ...fonts.subtitle,
      color: colors.text.mobile,
      padding: [0, spacing * 1.5, spacing * 2, spacing * 1.5],
    },
  },
  contactData: {
    padding: [0, 70],
    maxWidth: 220,
    '@media (max-width: 799px)': {
      marginTop: spacing * 9,
      padding: [0, spacing * 1.5],
    },
  },
  linksWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    margin: [spacing * 2, 0],
  },
  dropDownContainer: {
    width: `calc(50% - ${spacing * 2}px)`,
    paddingLeft: spacing * 2,
    marginTop: spacing * 8,
    paddingBottom: spacing * 2,
    '@media (max-width: 799px)': {
      width: '100%',
      padding: 0,
      marginTop: 0,
    },
  },
  dropDown: {
    marginBottom: spacing * 3,
    '@media (max-width: 799px)': {
      marginBottom: 0,
    },
  },
});
