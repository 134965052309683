import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ classes }) => <div className={classes.loader} />;

Spinner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default Spinner;
