import React, { useMemo } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { labels } from '../consts';

import { wrapVariants } from './utils';

const Input = ({ state, field, onChange, errors, classes, withTextArea }) => {
  const randomId = useMemo(() => `input-${field}-${Math.random().toString().slice(2)}`, [field]);

  return (
    <div className={classes.container}>
      <label htmlFor={randomId}>
        <span className={classes.label}>{labels[field]}</span>
      </label>
      <div className={classes.wrapper}>
        {withTextArea ? (
          <textarea
            id={randomId}
            name={field}
            value={state[field]}
            onChange={onChange}
            className={cn(classes.textarea, { [classes.errorInput]: Boolean(errors[field]) })}
            rows={5}
          />
        ) : (
          <input
            type="text"
            id={randomId}
            name={field}
            value={state[field]}
            onChange={onChange}
            className={cn(classes.input, { [classes.errorInput]: Boolean(errors[field]) })}
          />
        )}
        <motion.div className={classes.sign} variants={wrapVariants} animate={errors[field] ? 'show' : 'hide'} initial="hide">
          !
        </motion.div>
      </div>
      <motion.div className={classes.error} variants={wrapVariants} animate={errors[field] ? 'show' : 'hide'} initial="hide">
        {errors[field]}
      </motion.div>
    </div>
  );
};

Input.defaultProps = {
  errors: {},
  withTextArea: false,
};

Input.propTypes = {
  state: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  withTextArea: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Input;
