/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DropDown from '@/components/DropDown';
import TextContent from '@/components/TextContent';
import ContactsForm from '@/components/ContactsForm';

import Contact from './Contact';
import Content from './Content';

const Contacts = ({ pageContext, classes, location }) => {
  const { title, description, company, policy, mainContact, additionalContact, contactForm, content } = pageContext.contacts;

  const contacts = useMemo(
    () => (contactForm ? [mainContact, additionalContact] : [additionalContact]),
    [contactForm, additionalContact, mainContact]
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div>
          <p className={classes.title}>{title}</p>
          <p className={classes.description}>{description}</p>
          {contactForm ? <ContactsForm location={location} /> : <Contact contact={mainContact} />}
          {content && <Content content={content} />}
        </div>
        <div className={classes.contactList}>
          {contacts.map((contact, i) => (
            <Contact key={i} contact={contact} />
          ))}
        </div>
      </div>
      <div className={classes.dropDownContainer}>
        <DropDown title={company.title} className={classes.dropDown}>
          <TextContent texts={company.content} />
        </DropDown>
        <DropDown title={policy.title}>
          <TextContent texts={policy.content} smallText />
        </DropDown>
      </div>
    </div>
  );
};

Contacts.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  pageContext: PropTypes.shape({
    contacts: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      company: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
      policy: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
      mainContact: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])),
      additionalContact: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])),
      contactForm: PropTypes.string,
      content: PropTypes.objectOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Contacts;
