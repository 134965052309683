import React from 'react';
import PropTypes from 'prop-types';

import DropDown from '@/components/DropDown';

const Form = ({ classes, onSubmit, children }) => (
  <>
    <DropDown title="Kontaktformular" isOpen classes={classes} className={classes.dropDown}>
      <form onSubmit={onSubmit}>{children}</form>
    </DropDown>
    <form onSubmit={onSubmit} className={classes.form}>
      {children}
    </form>
  </>
);

Form.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Form;
