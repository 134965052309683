export default ({ colors, spacing, fonts }) => {
  const { primary, hover, click } = colors.link;

  return {
    animateBox: {
      display: 'flex',
      width: 'calc(100% + 50px)',
      marginLeft: -10,
      overflow: 'hidden',
      '@media (max-width: 799px)': {
        width: `calc(100% + ${spacing * 2})`,
        marginLeft: -1 * spacing,
      },
    },
    shadowWrapper: {
      width: 'calc(100% - 50px)',
      marginLeft: 10,
      '@media (max-width: 799px)': {
        width: `calc(100% - ${spacing * 2})`,
        marginLeft: spacing,
      },
    },
    dropDown: {
      display: 'none',
      '@media (max-width: 799px)': {
        display: 'block',
      },
    },
    contentContainer: {
      padding: [0, spacing * 1.5],
      boxShadow: 'none',
    },
    submit: {
      width: '100%',
      height: 44,
      ...fonts.subtitleSmall,
      marginTop: spacing,
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      color: '#fff',
      background: primary,
      '&:hover': {
        background: hover,
      },
      '&:active': {
        background: click,
      },
      '&:disabled': {
        background: primary,
        cursor: 'not-allowed',
      },
      '@media (max-width: 799px)': {
        marginTop: spacing * 3,
        '&:hover': {
          background: primary,
        },
      },
    },
    error: {
      ...fonts.smallText,
      color: colors.red,
      height: 11 * 1.73,
      paddingTop: 5,
    },
    success: {
      ...fonts.smallText,
      color: primary,
      height: 11 * 1.73,
      paddingTop: 5,
    },
    form: {
      '@media (max-width: 799px)': {
        display: 'none',
      },
    },
  };
};
