/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';

const TextContent = ({ texts, classes }) =>
  texts.map((text, index) => (
    <p key={index} className={classes.text}>
      {text.map((item, i) => (
        <Fragment key={i}>
          {item}
          <br />
        </Fragment>
      ))}
    </p>
  ));

export default TextContent;
