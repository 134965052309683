export const extractValidateErrors = ({ inner }) =>
  inner.reduce((acc, { path, message }) => {
    acc[path] = message;

    return acc;
  }, {});

export const hasValidErrors = (errors) => Boolean(errors && Object.keys(errors).length);

const transition = { duration: 0.3 };
export const wrapVariants = {
  deploy: { height: 'auto', transition },
  collapse: { height: 0, transition },
};

export const messageVariants = {
  show: { opacity: 1, transition },
  hide: { opacity: 0, transition },
};

export const getContactIdFromPath = (url) => {
  const res = /token=[a-z0-9]*/i.exec(url);
  return res ? res[0].slice(6) : res;
};
