export const NAME = 'name';
export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const MESSAGE = 'message';
export const INFO_REQUEST = 'informationRequest';
export const LEAD_REQUEST = 'leadRequest';
export const OTHER_REQUEST = 'otherRequest';

export const LOADING = 'loading';
export const SUCCESS = 'success';
export const ERRORS = 'errors';
export const REQUEST_ERROR = 'requestError';

export const SUBMIT_BUTTON = 'Senden';
export const DEFAULT_ERROR = 'Oups, ein Fehler ist aufgetreten.';
export const SUCCESS_MESSAGE = 'Vielen Dank für Ihre Nachricht. Wir melden uns so schnell wie möglich.';

export const initState = {
  [NAME]: '',
  [ADDRESS]: '',
  [ZIP_CODE]: '',
  [CITY]: '',
  [PHONE]: '',
  [EMAIL]: '',
  [MESSAGE]: '',
  [INFO_REQUEST]: false,
  [LEAD_REQUEST]: false,
  [OTHER_REQUEST]: false,
};

export const labels = {
  [NAME]: 'Name',
  [ADDRESS]: 'Strasse',
  [ZIP_CODE]: 'PLZ',
  [CITY]: 'Ort',
  [PHONE]: 'Telefon',
  [EMAIL]: 'E-Mail',
  [MESSAGE]: 'Nachricht',
  [INFO_REQUEST]: 'Ich möchte zusätzliches Informationsmaterial zu Augenmobil bestellen',
  [LEAD_REQUEST]: 'Ich möchte Augenmobil in meiner Institution (z.B. Pflegezentrum) anbieten',
  [OTHER_REQUEST]: 'Ich habe eine allgemeine Anfrage',
};
