import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { labels } from '../consts';

import Icon from './Icon';

const Checkbox = ({ state, field, onChange, classes }) => {
  const randomId = useMemo(() => `input-${field}-${Math.random().toString().slice(2)}`, [field]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.checkbox}>{state[field] && <Icon />}</div>
        <input type="checkbox" id={randomId} name={field} checked={state[field]} onChange={onChange} className={classes.input} />
      </div>
      <label htmlFor={randomId} className={classes.text}>
        {labels[field]}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  state: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Checkbox;
