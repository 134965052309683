export default {
  '@keyframes load8': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loader: {
    borderRadius: '50%',
    width: ({ size }) => size,
    height: ({ size }) => size,
    margin: 'auto',
    position: 'relative',
    textIndent: '-9999em',
    border: [3, 'solid', 'rgba(255, 255, 255, 0.2)'],
    borderLeft: [3, 'solid', '#fff'],
    transform: 'translateZ(0)',
    animationName: 'load8',
    animationDuration: '1.1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    '&:after': {
      borderRadius: '50%',
      width: ({ size }) => size,
      height: ({ size }) => size,
    },
  },
};
