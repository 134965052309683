/* eslint-disable react/no-array-index-key */
import React from 'react';
import withStyles from 'react-jss';
import { Link as InternLink } from 'gatsby';
import PropTypes from 'prop-types';

import Links from '@/components/Links';
import Button from '@/components/Button';

import DesktopIcon from './LinkIconDesktop';
import MobileIcon from './LinkIconMobile';
import styles from './styles';

const Contact = ({ contact, classes }) => {
  const { title, address, phones, links, faqLink, additional, additionalInfo, buttonsInfo } = contact;
  return (
    <div key={title} className={classes.itemContainer}>
      {additionalInfo && <div className={classes.additionalText}>{additionalInfo}</div>}
      <div className={classes.title}>{title}</div>
      {address &&
        address.map((adress, i) => (
          <p key={i} className={classes.text}>
            {adress}
          </p>
        ))}
      <div className={classes.linksWrapper}>
        {phones && <Links links={phones} classes={{ parentLink: classes.link }} />}
        {links && <Links links={links} classes={{ parentLink: classes.link }} />}
        {faqLink && (
          <InternLink to={faqLink.path} className={classes.faqLink}>
            <span className={classes.iconWrap}>
              <DesktopIcon />
              <MobileIcon />
            </span>
            <span>{faqLink.name}</span>
          </InternLink>
        )}
      </div>
      {additional &&
        additional.map((item, i) => (
          <p key={i} className={classes.text}>
            {item}
          </p>
        ))}
      <div className={classes.buttonsContainer}>
        {buttonsInfo && buttonsInfo.map((props, index) => <Button key={index} {...props} />)}
      </div>
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.shape({
    buttonsInfo: PropTypes.arrayOf(PropTypes.shape({})),
    additionalInfo: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.arrayOf(PropTypes.string.isRequired),
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
      }).isRequired
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        target: PropTypes.string,
      }).isRequired
    ),
    faqLink: PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
    additional: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(Contact);
