export default ({ colors, fonts }) => {
  const { primary, hover, click } = colors.link;

  return {
    link: {
      ...fonts.subtitle,
      textDecoration: 'none',
      color: primary,
      '&:hover': {
        color: hover,
      },
      '&:active': {
        color: click,
      },
    },
  };
};
