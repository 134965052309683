import React, { useCallback, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { postContactRequest, getContactInfo } from '@/server-api';
import Spinner from '@/components/Spinner';

import Form from './Form';
import Checkbox from './Checkbox';
import Input from './Input';
import {
  NAME,
  ADDRESS,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  MESSAGE,
  INFO_REQUEST,
  LEAD_REQUEST,
  OTHER_REQUEST,
  LOADING,
  SUCCESS,
  ERRORS,
  REQUEST_ERROR,
  SUBMIT_BUTTON,
  DEFAULT_ERROR,
  SUCCESS_MESSAGE,
  initState,
} from './consts';
import validationSchema from './validationSchema';
import { extractValidateErrors, hasValidErrors, getContactIdFromPath, wrapVariants, messageVariants } from './utils';
import { useAsyncState } from './hooks';

const ContactsForm = ({ classes, location }) => {
  const [state, setState] = useAsyncState(initState);
  const [alerts, setAlerts] = useAsyncState({});

  const postData = useCallback(
    (data) => {
      postContactRequest(data)
        .then(() => {
          setAlerts({ [SUCCESS]: SUCCESS_MESSAGE });
          setState({ ...initState });
        })
        .catch((error = {}) => setAlerts({ [REQUEST_ERROR]: error.message || DEFAULT_ERROR }));
    },
    [setAlerts, setState]
  );

  const handleValidationErrors = useCallback(
    (errors) => {
      setAlerts({ [ERRORS]: extractValidateErrors(errors) });
    },
    [setAlerts]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setAlerts({ [LOADING]: true });

      validationSchema
        .validate(state, { abortEarly: false, context: { [INFO_REQUEST]: state[INFO_REQUEST] } })
        .then(postData)
        .catch(handleValidationErrors);
    },
    [handleValidationErrors, postData, setAlerts, state]
  );

  const handleChange = useCallback(
    (e) => {
      const { name, type, value, checked } = e.target;

      const newState = {
        ...state,
        [name]: type === 'checkbox' ? checked : value,
      };

      setState(newState);

      if (hasValidErrors(alerts[ERRORS])) {
        validationSchema
          .validate(newState, {
            abortEarly: false,
            context: { [INFO_REQUEST]: newState[INFO_REQUEST] },
          })
          .then(setAlerts({ [ERRORS]: {} }))
          .catch(handleValidationErrors);
      }
    },
    [alerts, handleValidationErrors, setAlerts, setState, state]
  );

  const defaultProps = useMemo(
    () => ({
      state,
      onChange: handleChange,
      errors: alerts[ERRORS],
    }),
    [alerts, handleChange, state]
  );

  useEffect(() => {
    const id = getContactIdFromPath(location.href);
    if (id) {
      getContactInfo(id).then((res) => {
        setState({
          ...state,
          [LEAD_REQUEST]: true,
          [NAME]: res[0].name,
          [EMAIL]: res[0].email,
          [PHONE]: res[0].phone,
          [MESSAGE]:
            'Ich möchte Augenmobil gerne in einem persönlichen Gespräch unverbindlich kennenlernen. Bitte nehmen Sie mit mir Kontakt auf.',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form onSubmit={handleSubmit} classes={classes}>
      <Checkbox field={LEAD_REQUEST} {...defaultProps} />
      <Checkbox field={INFO_REQUEST} {...defaultProps} />
      <Checkbox field={OTHER_REQUEST} {...defaultProps} />
      <Input field={NAME} {...defaultProps} />
      <Input field={EMAIL} {...defaultProps} />
      <Input field={PHONE} {...defaultProps} />
      <motion.div
        className={classes.animateBox}
        variants={wrapVariants}
        animate={state[INFO_REQUEST] ? 'deploy' : 'collapse'}
        initial="collapse"
      >
        <div className={classes.shadowWrapper}>
          <Input field={ADDRESS} {...defaultProps} />
          <Input field={ZIP_CODE} {...defaultProps} />
          <Input field={CITY} {...defaultProps} />
        </div>
      </motion.div>
      <Input field={MESSAGE} {...defaultProps} withTextArea />
      <button type="submit" className={classes.submit} disabled={hasValidErrors(alerts[ERRORS]) || alerts[LOADING]}>
        {alerts[LOADING] ? <Spinner size={30} /> : SUBMIT_BUTTON}
      </button>
      <motion.div
        className={cn({
          [classes.error]: Boolean(alerts[REQUEST_ERROR]),
          [classes.success]: Boolean(alerts[SUCCESS]),
        })}
        variants={messageVariants}
        animate={alerts[REQUEST_ERROR] || alerts[SUCCESS] ? 'show' : 'hide'}
        initial="hide"
      >
        {alerts[SUCCESS] || alerts[REQUEST_ERROR]}
      </motion.div>
    </Form>
  );
};

ContactsForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContactsForm;
