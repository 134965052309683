export default ({ spacing, fonts, colors }) => ({
  container: {
    width: '100%',
  },
  label: {
    display: 'inline-block',
    ...fonts.subtitle,
    margin: [spacing, 3 * spacing],
    opacity: 0.6,
    '@media (max-width: 799px)': {
      ...fonts.subtitle['@media (max-width: 799px)'],
      margin: [spacing * 1.5, 2 * spacing],
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 33,
    border: 'none',
    boxShadow: [0, '2px', '15px', 0, 'rgba(204, 204, 204, 0.5)'],
    padding: [0, 3 * spacing],
    ...fonts.subtitleSmall,
    outline: 'none',
    boxSizing: 'border-box',
    '@media (max-width: 799px)': {
      ...fonts.subtitle,
      padding: [0, 2 * spacing],
    },
  },
  textarea: {
    width: '100%',
    border: 'none',
    boxShadow: [0, '2px', '15px', 0, 'rgba(204, 204, 204, 0.5)'],
    padding: [2 * spacing, 3 * spacing],
    ...fonts.subtitleSmall,
    resize: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    '@media (max-width: 799px)': {
      ...fonts.subtitle,
      padding: [spacing, 2 * spacing],
    },
  },
  errorInput: {
    background: '#ffdfdf',
  },
  sign: {
    position: 'absolute',
    right: -34,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    borderRadius: '50%',
    border: [1, 'solid', '#f00'],
    ...fonts.subtitle,
    color: colors.red,
    '@media (max-width: 799px)': {
      display: 'none',
    },
  },
  error: {
    paddingTop: 5,
    ...fonts.smallText,
    color: colors.red,
    height: 11 * 1.73,
    '@media (max-width: 799px)': {
      ...fonts.smallText['@media (max-width: 799px)'],
      height: 'auto',
    },
  },
});
