export default ({ colors, spacing, fonts }) => ({
  itemContainer: {
    marginBottom: spacing * 6,
    width: 273,
    '@media (max-width: 799px)': {
      width: '100%',
      marginBottom: 0,
    },
  },
  title: {
    ...fonts.subtitleBold,
    color: '#121212',
    '@media (max-width: 799px)': {
      padding: [0, spacing * 1.5],
    },
  },
  text: {
    ...fonts.subtitle,
    color: '#121212',
    '@media (max-width: 799px)': {
      ...fonts.subtitle['@media (max-width: 799px)'],
      color: colors.text.mobile,
      padding: [0, spacing * 1.5],
    },
  },
  linksWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '1em',
    marginTop: '1em',
    '@media (max-width: 799px)': {
      marginBottom: '3em',
      padding: [0, spacing * 1.5],
    },
  },
  link: {
    display: 'block',
    width: 'fit-content',
  },
  buttonsContainer: {
    display: 'none',
    '@media (max-width: 799px)': {
      display: 'block',
      width: '100vw',
      marginBottom: spacing * 3,
    },
  },
  button: {
    width: '100%',
  },
  additionalText: {
    ...fonts.subtitle,
    color: '#121212',
    marginBottom: 20,
    '@media (max-width: 799px)': {
      padding: [0, spacing * 1.5],
    },
  },
  faqLink: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    ...fonts.subtitle,
    textDecoration: 'none',
    color: colors.link.primary,
    '&:hover': {
      color: colors.link.hover,
    },
    '&:active': {
      color: colors.link.click,
    },
  },
  iconWrap: {
    height: 18,
    marginRight: spacing * 1.25,
    '&>svg:last-child': {
      display: 'none',
    },
    '@media (max-width: 799px)': {
      height: 23,
      marginRight: spacing * 2,
      '&>svg:first-child': {
        display: 'none',
      },
      '&>svg:last-child': {
        display: 'initial',
      },
    },
  },
});
